import React from "react"
import { useModal } from "../../contexts/modalContext";
import Modal from "../modal";

const Contact = () => {

    const { setModal } = useModal()

    return(
        <section id="contact" className="bg-dark min-h-screen w-full border-t border-white border-opacity-10 relative">
            <div className="flex flex-col items-center justify-center h-full">
                <div className="flex-1 flex items-center justify-center h-full pt-8">
                    <button 
                        onClick={() => {
                            setModal(<Modal/>)
                        }}
                        className="md:w-[400px] md:h-[400px] 2xl:w-[450px] 2xl:h-[450px] p-10 aspect-square border border-white border-opacity-25 hover:border-opacity-50 transition-basic rounded-full flex items-center justify-center relative overflow-hidden">
                        <h3 className="text-4xl md:text-5xl font-playfair tracking-tight leading-[35px] md:leading-[45px] font-medium text-center text-white">
                            Register Your
                            <span className="text-primary block italic font-normal">Interest</span>
                        </h3>
                    </button>
                </div>
                
                <footer className="py-10 text-center">
                    <div className="">
                        <h3 className="text-2xl font-playfair tracking-tight font-medium text-center text-white mb-3">For General Enquiries</h3>
                        <p className="text-white font-light mb-5 tracking-wide text-sm">
                            <a href="mailto:centralgurney@gmail.com" className="text-primary underline hover:text-opacity-75 font-normal transition-basic inline-block">centralgurney@gmail.com</a> 
                        {' '} or call {' '}
                        <a href="tel:+0164411207" className="text-primary underline hover:text-opacity-75 font-normal transition-basic inline-block">04-2380000</a>
                        </p>
                        <h3 className="text-2xl font-playfair tracking-tight font-medium text-center text-white mb-3">For Leasing Enquiries</h3>
                        <p className="text-white font-light mb-2 tracking-wide text-sm">
                            Mr Faizal at {' '}
                            <a href="mailto:faizal@centralgurney.com.my" className="text-primary underline hover:text-opacity-75 font-normal transition-basic inline-block">faizal@centralgurney.com.my</a> 
                            {' '} or call {' '}
                            <a href="tel:+0164411207" className="text-primary underline hover:text-opacity-75 font-normal transition-basic inline-block">016-441 1207</a>
                        </p>
                        <p className="text-white font-light mb-3 text-sm">
                            Ms Janice Lim at {' '}
                            <a href="mailto:janice@centralgurney.com.my" className="text-primary underline hover:text-opacity-75 font-normal transition-basic inline-block">janice@centralgurney.com.my</a> 
                            {' '} or call {' '}
                            <a href="tel:+0164548714" className="text-primary underline hover:text-opacity-75 font-normal transition-basic inline-block">016-454 8714</a>
                        </p>
                    </div>
                </footer>

                <div className="flex flex-row flex-wrap w-full mb-16 px-8">
                    <div className="md:flex-1"></div>
                    <div className="flex-1 text-center">
                        <p className="text-white font-light text-sm mb-1">Copyright © {new Date().getFullYear()} G Hotel. All rights reserved.</p>
                        <p className="text-white text-opacity-50 font-light text-sm">All pictures shown are for illustration purposes only.</p>
                    </div>
                    <div className="w-full md:flex-1 mt-5 md:mt-0 text-center md:text-right">
                        <a href="https://tenant.centralgurney.com.my/login" className="text-primary text-sm underline mb-3 inline-block">Tenant Login</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;
